<template>
  <section class="error-404">
    <div class="container">
      <h1 class="error-404__title">
        Błąd 404
      </h1>

      <p class="error-404__text">
        No to jesteśmy w lesie.<br />
        Strona, której szukasz nie istnieje lub została usunięta.
      </p>

      <router-link
        :to="{ name: 'home' }"
        name="home"
        class="button button--alt button--strong button--large"
      >
        Wróć na stronę główną
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Error404',
  metaInfo: {
    title: 'Błąd 404. Strona o podanym adresie nie istnieje.',
  },
};
</script>
